import { GlobalProvider } from './contexts/globalContext';
import Routes from './routes/Thema';
// import { BrowserRouter } from 'react-router-dom';
// import history from './services/history';

function App() {
    return (
        <GlobalProvider>
            <Routes />
        </GlobalProvider>
    );
}

export default App;
